import React, { Fragment, useEffect, useRef, useState } from "react";
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import axios from "axios";
import { API_URL } from "../../../../apiconfig";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let styles={"cursor":'not-allowed',"background":"#DDDDDD"}
const EditCorporatePlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    agency_name: '',
    email: '',
    phone: '',
    local_govt: '',
    contact_person: '',
    no_of_staff: '',
    budget: '',
    state: '',
  });
  useEffect(async () => {
    const { data } = await axios.get(`${API_URL}/api/indivual_plan_master/${id}`)
    const {agency_name,email,phone,local_govt,contact_person,no_of_staff,budget,state} = data
    setFormData({...formData,
      agency_name:agency_name,
      email:email,
      phone:phone,
      local_govt:local_govt,
      contact_person:contact_person,
      no_of_staff:no_of_staff,
      budget:budget,
      state:state
      
    })
    // setState(data)
    // console.log(data);

  }, [])


  const handleRedirect = async (e) => {
    navigate('/admin/view/view_corporate_plan')
  };


  return (
    <Fragment>
      <AdminPageTitle activePage="Manage orders" pageName="View Corporate Plan" />

      <div className="col-xl-12 row-lg-12" >
        <div className="card" >
          <div className="card-header">
            <h4 className="card-title">View Corporate Plan</h4>
          </div>

          <div className="card-body">
            <div className="basic-form">

              <form onSubmit={handleRedirect}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Agency Name</label>
                    <div className="col-sm-9">
                      <input
                        name="agency_name"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.agency_name}
                        style={styles}

                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="enter valid email"
                        value={formData.email}
                        disabled
                        style={styles}
                      />
                    </div>
                  </div>
               
                </div>

                <div className="row">
               
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Contact No</label>
                    <div className="col-sm-9">

                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Contact No"
                        style={styles}
                        disabled
                        // onBlur={handleBlur}

                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Contact Person</label>
                    <div className="col-sm-9">
                    <input
                        name="contact_person"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.contact_person}
                        style={styles}

                      />
                    </div>
                  </div>

                </div>
                <div className="row">

          
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">State</label>
                    <div className="col-sm-9">
                    <input
                        name="state"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.state}
                        style={styles}

                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Local Goverment</label>
                    <div className="col-sm-9">

                    <input
                        name="local_govt"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.local_govt}
                        // onChange={handleChange}
                        style={styles}
                      />
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">No of staff</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="no_of_staff"
                        className="form-control"
                        value={formData.no_of_staff}
                        disabled
                        style={styles}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Budget</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="budget"
                        className="form-control"
                        value={formData.budget}
                        disabled
                        style={styles}

                      />
                    </div>
                  </div>

                </div>
                <div className="col-sm-10">
                  <button type="submit" className="btn btn-primary">
                    Close
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default EditCorporatePlan;
