import React, {
  useContext,
  useEffect,
  Fragment,
  useState,
  useReducer,
} from "react";
import Stripe from "stripe";
//Import
import { ThemeContext } from "../../../context/ThemeContext";
import PageHead from "../Griya/PageHead";
import axios from "axios";
import { API_URL } from "../../../apiconfig";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { CChart } from '@coreui/react-chartjs'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import CustomerMap from '../Griya/Home/CustomerMap';
// import OverView from '../Griya/Home/OverView';
// import PieChartSection from '../Griya/Home/PieChartSection';
// import PropetiesMap from '../Griya/Home/PropetiesMap';
// import ProgressBar from '../Griya/Home/ProgressBar';
// import CustomerReview from '../Griya/Home/CustomerReview';
// import RecentCustomer from '../Griya/Home/RecentCustomer';
const filterStyle = { width: "fit-content", marginLeft: "8px", marginTop: "10px" }
const DatefilterStyle = { width: "12%", marginLeft: "8px", marginTop: "10px" }
const Home = () => {
  const navigate = useNavigate()
  const { changeBackground } = useContext(ThemeContext);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedEndYear, setEndSelectedYear] = useState(currentYear);
  const [selectedEndMonth, setEndSelectedMonth] = useState(1);
  const [selectedEndDay, setEndSelectedDay] = useState(1);
  const [date, setDate] = useState(`${selectedYear}-${selectedMonth}-${selectedDay}`);
  const [filterType, setFIlterType] = useState("week");
  const [endDate, setEndDate] = useState(`${selectedEndYear}-${selectedEndMonth}-${selectedEndDay}`);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [graphData, setGraphData] = useState([])
  const [graphLabels, setGraphLabels] = useState([])
  const [planType, setPlanType] = useState("Individual & Family");
  const [userData, setUserData] = useState({
    TotalHMO: 0,
    TotalHospitals: 0,
    TotalProducts: 0,
    TotalIndividulPlans: 0,
    TotalCorporatePlans: 0,
    TotalStatePlans: 0,
    TotalTravelPlans: 0,
  });
  const [plansCount, setPlansCount] = useState({
    totalIndividulPlans: 0,
    totalCorporatePlans: 0,
    totalStatePlans: 0,
    totalTravelPlans: 0,
  })
  //   const { search } = useLocation();
  //   const params = new URLSearchParams(search);
  //   const sessionId = params.get('id');
  //   console.log("session_id",sessionId);
  // const [amount,setAmount] = useState(0)
  useEffect(async () => {
    const response = await axios.get(`${API_URL}/api/hmo/count`);
    const response1 = await axios.get(`${API_URL}/api/hospital/count`);
    const response2 = await axios.get(`${API_URL}/api/Planpolicy_mapping_master/count`);
    const response3 = await axios.get(`${API_URL}/api/indivual_plan_master/count`);
    const currentDate = new Date().toISOString().split('T')[0];
    const nextDay = new Date(new Date().getTime() + 86400000).toISOString().split('T')[0];
    setDate(currentDate);
    setEndDate(nextDay)
    getAllData()
    // console.log(response.data.count,"success");
    setUserData({
      ...userData,
      TotalHMO: response.data.count,
      TotalHospitals: response1.data.count,
      TotalProducts: response2.data.count,
      TotalIndividulPlans: response3.data.count,
    });
    changeBackground({ value: "light", label: "Light" });
  }, [filterType]);
  const getAllData = async () => {
    let postData = {
      plan_type: planType,
      startDate: `${date}`,
      endDate: `${endDate}`,
      period: filterType
    }
    try {
      const { data } = await axios.post(`${API_URL}/api/corporate_plan_master/getweek`, postData)
      console.log("postData", data);

      if (data.status === 200) {
        setPlansCount({
          totalIndividulPlans: data.data.Individual,
          totalCorporatePlans: data.data.Corporate,
          totalStatePlans: data.data.State,
          totalTravelPlans: data.data.Travel,
        })
        setGraphData(data && data.data.counts)
        setGraphLabels(data && data.data.labels)
      }
    } catch (error) {

    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    updateDate(event.target.value, selectedMonth, selectedDay);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    updateDate(selectedYear, event.target.value, selectedDay);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
    updateDate(selectedYear, selectedMonth, event.target.value);
  };
  const handleEndYearChange = (event) => {
    setEndSelectedYear(event.target.value);
    updateEndDate(event.target.value, selectedEndMonth, selectedEndDay);
    // setEndDate(`${selectedEndDay}/${selectedEndMonth}/${event.target.value}`)
  };

  const handleEndMonthChange = (event) => {
    setEndSelectedMonth(event.target.value);
    updateEndDate(selectedEndYear, event.target.value, selectedEndDay);
    // setEndDate(`${selectedEndDay}/${event.target.value}/${selectedEndYear}`)
  };

  const handleEndDayChange = (event) => {
    setEndSelectedDay(event.target.value);
    updateEndDate(selectedEndYear, selectedEndMonth, event.target.value);
  };

  const updateEndDate = (year, month, day) => {
    const maxDays = new Date(year, month, 0).getDate();

    if (day > maxDays) {
      day = maxDays;
    }
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');

    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    setEndDate(formattedDate);
  };


  const updateDate = (year, month, day) => {
    if (year && month && day) {
      // Ensure the month and day are always two digits (e.g., 01, 09)
      const formattedMonth = String(month).padStart(2, '0');
      const formattedDay = String(day).padStart(2, '0');

      // Create the formatted date string in "YYYY-MM-DD" format
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      setDate(formattedDate);

      // console.log("Formatted Date:", formattedDate);
    }
  };
  const handleFilterType = async (g) => {
     setFIlterType(g)
    console.log("type", g);
    if(g !== "date"){
      let postData = {
        plan_type: planType,
        startDate: `${date}`,
        endDate: `${endDate}`,
        period: filterType
      }
      try {
        const { data } = await axios.post(`${API_URL}/api/corporate_plan_master/getweek`, postData)
        console.log("postData", data);
  
        if (data.status === 200) {
          setPlansCount({
            totalIndividulPlans: data.data.Individual,
            totalCorporatePlans: data.data.Corporate,
            totalStatePlans: data.data.State,
            totalTravelPlans: data.data.Travel,
          })
          setGraphData(data && data.data.counts)
          setGraphLabels(data && data.data.labels)
        }
      } catch (error) {
  
      }
    }else{
      setEndDate("")
      setDate("")
    }

  }
  const handleDateFilter = async () => {
    setShow2(false)
    let postData = {
      plan_type: planType,
      startDate: `${date}`,
      endDate: `${endDate}`,
      period: filterType
    }
    try {
      const { data } = await axios.post(`${API_URL}/api/corporate_plan_master/getweek`, postData)
      console.log("postData", data);

      if (data.status === 200) {
        setPlansCount({
          totalIndividulPlans: data.data.Individual,
          totalCorporatePlans: data.data.Corporate,
          totalStatePlans: data.data.State,
          totalTravelPlans: data.data.Travel,
        })
        setGraphData(data && data.data.counts)
        setGraphLabels(data && data.data.labels)
      }
    } catch (error) {

    }
  };
  const handlePlanType = async (g) => {
    setPlanType(g)
    try {
      const { data } = await axios.post(`${API_URL}/api/corporate_plan_master/getweek`, {
        "plan_type": "Individual & Family",
        "period": "week",
        "planType": "Individual & Family",
        "startDate": date,
        "endDate": endDate
      })
      console.log("startDate", data);


    } catch (error) {
      // console.error("Error fetching data", error);
    }


  };
  //  const getId =async ()=>{
  //   if(  sessionId){
  //     const session = await  Stripe.checkout.sessions.retrieve(sessionId);
  //     console.log("session_id",sessionId);
  //     // console.log("session",);
  //   }
  // }
  // const getStripeTest = async ()=>{
  //   const stripe = new Stripe(  "sk_test_51OcN1bSBagUy9FTJ8VjAdf8nXiqaLY89ZQV4Up8CNvujHMrLFfV7kcDpPMakEHit9qyGwAmyO7SfsAvy98yjKN0W00Pbcv539f", {
  //     apiVersion: "2022-11-15",
  //   });

  //   const session = await stripe.checkout.sessions.create({
  //     payment_method_types:["card"],
  //     line_items:[{
  //       price_data : {
  //         currency:'usd',
  //         product_data:{
  //           name:"Xpro"
  //         },
  //         unit_amount:Number(amount)*100

  //       },
  //       quantity:1
  //   }],
  //     mode: "payment",
  //     // success_url: "http://localhost:3000/admin/view/dashboard",
  //     success_url: "http://localhost:3000/admin/view/ViewHMO/?id={CHECKOUT_SESSION_ID}",
  //     cancel_url: "http://localhost:3000/admin/view/dashboard",
  // })

  // console.log("session",session);
  // }
  // const handleChange =(e)=>{
  //   setAmount(Number(e.target.value))
  // console.log("target",e.target.value);
  // }

  return (
    <>
      <PageHead activePage="Dashboard" pageName="Dashboard" />
      <div class="row">
        <Card>
          <Card.Header>
            <h4 className="card-title"> All Data</h4>
          </Card.Header>
          <Card.Body style={{ display: "flex", flexWrap: "wrap" }}>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-info">
                <div class="inner">
                  <h3 style={{ color: "#6b3f8f" }}>{userData.TotalHMO}</h3>

                  <p style={{ color: "#6b3f8f" }}>Total HMO</p>
                </div>
                <div class="icon">
                  <i style={{ color: "#6b3f8f" }} class="ion ion-bag"></i>
                </div>
                <a href="/admin/view/ViewHMO" class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3 style={{ color: "#269323" }}>{userData.TotalHospitals}</h3>

                  <p style={{ color: "#269323" }}>Total Hospitals</p>
                </div>
                <div class="icon">
                  <i style={{ color: "#269323" }} class="ion ion ion-bag"></i>
                </div>
                <a href="/admin/view/ViewHospitals" class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3 style={{ color: "#b75b06" }}>{userData.TotalProducts}</h3>

                  <p style={{ color: "#b75b06" }}>Total Products</p>
                </div>
                <div class="icon">
                  <i style={{ color: "#b75b06" }} class="ion ion ion-bag"></i>
                </div>
                <a onClick={() => navigate("/admin/view/view_plan")} style={{ cursor: "pointer" }} class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="small-box bg-danger">
                <div class="inner">
                  <h3 style={{ color: "#9F0621" }} >{userData.TotalIndividulPlans}</h3>

                  <p style={{ color: "#9F0621" }} ><b>Total Individual Plans</b></p>
                </div>
                <div class="icon">
                  <i style={{ color: "#9F0621" }} class="ion ion ion-bag"></i>
                </div>
                <a href="/admin/view/view_individual_plan" class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </Card.Body>
        </Card>



        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className='bg-primary'>
            <Modal.Title>Select Start Date<i class="ri-calendar-2-line text-white"></i></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Year</label>
              <select value={selectedYear} onChange={handleYearChange} className="form-control">
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Month</label>
              <select value={selectedMonth} onChange={handleMonthChange} className="form-control">
                {months.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Day</label>
              <select value={selectedDay} onChange={handleDayChange} className="form-control">
                {days.map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Date
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show2} onHide={() => setShow2(false)} centered>
          <Modal.Header closeButton className='bg-primary'>
            <Modal.Title>Select end Date<i class="ri-calendar-2-line text-white"></i></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Year</label>
              <select value={selectedEndYear} onChange={handleEndYearChange} className="form-control">
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Month</label>
              <select value={selectedEndMonth} onChange={handleEndMonthChange} className="form-control">
                {months.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Day</label>
              <select value={selectedEndDay} onInput={handleEndDayChange} className="form-control">
                {days.map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow2(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDateFilter}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          {/* <Col xl={12} lg={12}> */}
          <Card>
            <Card.Header >
              <h4 className="card-title">Total Sales & Income</h4>
            </Card.Header>
            <Card.Body>
              <Row style={{ width: "60%" }}>
                <Col style={{ width: "50%" }}>
                  <label className="row-sm-3 col-form-label" >Filter by</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => handleFilterType(e.target.value)}
                    // style={{ width: "fit-content", marginLeft: "20px", marginTop: "10px" }}
                    aria-placeholder="Filter"
                  >
                    <option value={"week"}>Week</option>
                    <option value={"month"}>Monthy</option>
                    <option value={'year'}>Yearly</option>
                    <option value={'date'}>Date</option>
                  </select>

                </Col>
                <Col>
                  <label className="row-sm-3 col-form-label" >Filter by plan</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => handlePlanType(e.target.value)}
                    style={{ width: "fit-content" }}
                    aria-placeholder="Filter"

                  >
                    <option value={"Individual & Family"}>Individual & Family</option>
                    <option value={"Corporate"}>Corporate</option>
                    <option value={'Travel'}>Travel</option>
                    <option value={'State'}>State</option>
                  </select>

                </Col>
                {filterType === 'date' && <Col>
                  <label className="row-sm-3 col-form-label" >Start Date</label>
                  <input
                    // style={DatefilterStyle}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Start date"
                    value={date}
                    name="date"
                    readOnly

                    onClick={handleShow} // Show the modal on input click
                  />
                </Col>}
                {filterType === 'date' && <Col>
                  <label className="row-sm-3 col-form-label" >End Date</label>
                  <input
                    // style={DatefilterStyle}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="End date"
                    value={endDate} // Here should be endDate, not endDate.date
                    readOnly
                    name="endDate"
                    onClick={() => setShow2(true)} // Show the modal on input click
                  />
                </Col>}

              </Row>

              <hr style={{ marginBottom: "2%", marginTop: "2%", color: "black" }} />
              <Row>
                <div class="col-lg-3 col-6">
                  <div class="small-box bg-dark" >
                    <div class="inner">
                      <h3 style={{ color: "#444444" }}>{plansCount.totalIndividulPlans}</h3>

                      <p style={{ color: "#444444" }} ><b>Individual Plan</b></p>
                    </div>
                    <div class="icon">
                      <i style={{ color: "#444444" }} class="ion ion-bag"></i>
                    </div>
                    <a onClick={() => navigate("/admin/view/view_individual_plan")} style={{ cursor: "pointer" }} class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>

                <div class="col-lg-3 col-6">
                  <div class="small-box bg-light" >
                    <div class="inner">
                      <h3 style={{ color: "rgb(133 131 131)" }}>{plansCount.totalCorporatePlans}</h3>
                      <p style={{ color: "rgb(133 131 131)" }}> <b>Corporate Plan</b> </p>
                    </div>
                    <div class="icon">
                      <i style={{ color: "rgb(133 131 131)" }} class="ion ion ion-bag"></i>
                    </div>
                    <a onClick={() => navigate("/admin/view/view_corporate_plan")} style={{ cursor: "pointer" }} class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>

                <div class="col-lg-3 col-6">
                  <div class="small-box bg-primary">
                    <div class="inner">
                      <h3 style={{ color: "#004E2B" }} >{plansCount.totalStatePlans}</h3>

                      {/* <p style={{color:"#008D4D"}}>  </p> */}
                      <p style={{ color: "#004E2B" }}> <b>State Plans</b> </p>
                    </div>
                    <div class="icon">
                      <i style={{ color: "#004E2B" }} class="ion ion ion-bag"></i>
                    </div>
                    <a onClick={() => navigate("/admin/view/view_state_plan")} style={{ cursor: "pointer" }} class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>

                <div class="col-lg-3 col-6">
                  <div class="small-box bg-black">
                    <div class="inner">
                      <h3 style={{ color: "rgb(133 131 131)" }} >{plansCount.totalTravelPlans}</h3>

                      <p style={{ color: "rgb(133 131 131)" }}> <b>Travel Plans</b> </p>
                    </div>
                    <div class="icon">
                      <i style={{ color: "rgb(133 131 131)" }} class="ion ion ion-bag"></i>
                    </div>
                    <a onClick={() => navigate("/admin/view/view_travel_plan")} style={{ cursor: "pointer" }} class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </Row>
              <div style={{ border: "1px solid gray", padding: "5px" }}>
                <CChart
                customTooltips={false}
                  type="bar"
                  data={{
                    labels: graphLabels,
                    datasets: [
                      {
                        label: 'Total Report',
                        backgroundColor: '#f87979',
                        data: graphData,
                      },
                    ],
                  }}
                  labels="months"
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: "black",
                        }
                      }
                    },
                    tooltip: {
                      enabled: true,
                      displayColors: true,
                      position: (context) => {
                        const { chart, tooltip } = context;
                        const { offset } = chart;
                        const { width, height } = tooltip;
                        const x = offset.left + (offset.width - width) / 2;
                        const y = offset.top + (offset.height - height) / 2;
                        return { x, y };
                      },
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
                        },
                      },
                    },
                    scales: {
                      x: {
                        grid: {
                          color: "",
                        },
                        ticks: {
                          color: "black",
                        },
                      },
                      y: {
                        grid: {
                          color: "pink",
                        },
                        ticks: {
                          color: "black",
                        },
                      },
                    },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
          {/* </Col> */}
        </Row>
      </div>
    </>
  );
};
export default Home;
